import React from 'react';
import {Menu} from "antd";
import Link from "../Link";
import { StaticQuery, graphql } from "gatsby";

class TopNavWelsh extends React.Component {


    render() {
        const isPartiallyActive = ({isPartiallyCurrent}) => {
            return isPartiallyCurrent
                ? { className: "activeLink" }
                : null
        };

        return (
            <Menu mode="vertical">
                {this.props.links.map((link) => {
                    // add a trailing slash
                    if (link.url.substr(-1) !== '/') link.url += '/';
                    return <Menu.Item key={link.wordpress_id}><Link to={link.url} getProps={isPartiallyActive} dangerouslySetInnerHTML={{__html: link.title}}/></Menu.Item>
                })}
            </Menu>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query NavItemLinksWelsh {
                allWordpressWpApiMenusMenusItems (filter: {slug: {eq:"welsh-header-menu"}}){
                    edges {
                        node {
                            id
                            slug
                            description
                            wordpress_id
                            items {
                                title
                                url
                                target
                                classes
                                order
                                wordpress_id
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <TopNavWelsh links={data.allWordpressWpApiMenusMenusItems.edges[0].node.items} />
        )}
    />
)
