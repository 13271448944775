import React from 'react';
import './style.less';
import {Button} from "antd";
import Link from "../Link";
import TopNavEnglish from "../TopNavEnglish";
import TopNavWelsh from "../TopNavWelsh";

import language from "../../../data/language.yaml";

class TopNav extends React.Component {

    constructor(props){
        super(props);

        const enActive = this.props.locale === 'en' ? 'active' : '';
        const cyActive = this.props.locale === 'cy' ? 'active' : '';

        let enTarget = '/';
        let cyTarget = '/cy/';
        if(this.props.translations) {
            enTarget = this.props.translations.en;
            cyTarget = this.props.translations.cy;
        }

        this.state = {
            open: false,
            enTarget: enTarget,
            cyTarget: cyTarget,
            enActive: enActive,
            cyActive: cyActive
        };
    }

    openMobileNav = () => {
        this.setState({
            open : !this.state.open,
        });
    };

    changeLanguage = () => {
        if(this.state.enActive){
            window.location.href = this.state.cyTarget;
        } else {
            window.location.href = this.state.enTarget;
        }
    };

    render() {


        return (
            <React.Fragment>
                <span className="button-wrap">
                    <Button className="menu-trigger" onClick={this.openMobileNav} open={this.state.open}>
                        <span className="icon" />
                        {language.menu[this.props.locale]}
                    </Button>
                </span>
                <div className="menu-wrapper" open={this.state.open}>
                    <div className="controls">
                        <Link className={`btn btn-white btn-small ${this.state.enActive}`} onClick={this.changeLanguage}>
                            <span>English</span>
                            <span className="icon arrow" />
                        </Link>
                        <Link className={`btn btn-white btn-small ${this.state.cyActive}`} onClick={this.changeLanguage}>
                            <span>Cymraeg</span>
                            <span className="icon arrow" />
                        </Link>
                        <Button className="menu-close" onClick={this.openMobileNav} open={this.state.open}>
                            <span className="icon" />
                            {language.close[this.props.locale]}
                        </Button>
                    </div>
                    {this.props.locale === 'en' &&
                    <TopNavEnglish/>
                    }
                    {this.props.locale === 'cy' &&
                    <TopNavWelsh/>
                    }

                </div>
            </React.Fragment>
        );
    }
}

export default TopNav
