import React from 'react'
import './style.less';
import { Layout, Row, Col } from 'antd';
import Link from "../Link";
import TopNav from "../TopNav";
import S360Logo from "../S360Logo";

const { Header } = Layout;

class Head extends React.Component {

    render(){

        return (
            <Header>
                <Row gutter={70}>
                    <Col>
                        {this.props.locale === 'en' &&
                            <Link className="site-logo" to="/">
                                <S360Logo logoStyle={"small"}/>
                            </Link>
                        }
                        {this.props.locale === 'cy' &&
                            <Link className="site-logo" to="/cy/">
                                <S360Logo logoStyle={"small"}/>
                            </Link>
                        }
                    </Col>
                </Row>
                <TopNav
                    locale={this.props.locale}
                    translations={this.props.translations}
                />
            </Header>
        );
    }
}

export default Head
