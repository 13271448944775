import React, {Component} from 'react'

import './wrapper.less'
import { Layout } from 'antd';
import MetaData from './MetaData'
import Head from "./Head";
import Foot from "./Foot";
import {LocaleContext} from "../context/LocaleContext";
import Announcement from "./Announcement";
import CookieConsent from "react-cookie-consent";

import language from "../../data/language.yaml";

class Wrapper extends Component {

    constructor(props, context){
        super(props);
        context.setLocale(this.props.locale);
    }

    render() {
        const { children } = this.props;

        return (
            <div className="app">
                <MetaData
                    title={this.props.title}
                    description={this.props.description}
                    og_title={this.props.og_title}
                    og_description={this.props.og_description}
                    og_image={this.props.og_image}
                    twitter_card_type={this.props.twitter_card_type}
                    twitter_title={this.props.twitter_title}
                    twitter_description={this.props.twitter_description}
                    twitter_image={this.props.twitter_image}
                    canonical={this.props.canonical}
                    schema={this.props.schema}
                    path={this.props.path}
                    locale={this.props.locale}
                    translations={this.props.translations}
                />
                <Layout className="layout">
                    <LocaleContext.Consumer>
                        {({ locale }) => (
                            <>
                                <Announcement/>
                                <Head
                                    locale={this.props.locale}
                                    translations={this.props.translations}
                                />

                                <div className="mainContent">
                                    {children}
                                </div>

                                <CookieConsent
                                    buttonText={language.cookieMessage.btnText[this.props.locale]}
                                >
                                    {language.cookieMessage[this.props.locale]}
                                </CookieConsent>

                                <Foot locale={this.props.locale} />
                            </>
                        )}
                    </LocaleContext.Consumer>
                </Layout>
            </div>
        )
    }
}

Wrapper.contextType = LocaleContext;

export default Wrapper
