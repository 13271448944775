import React from 'react';
import {graphql, StaticQuery} from "gatsby";
import {LocaleContext} from "../../context/LocaleContext";
import CookieConsent from "react-cookie-consent";
import language from "../../../data/language.yaml";

class Announcement extends React.Component {

    render() {
        if (!this.props.announcement.announcement_show) {
            return null;
        }
        const locale = this.context.locale;
        let lang     = {};
        lang = {
            title:   this.props.announcement['announcement_title_' + locale],
            content: this.props.announcement['announcement_content_' + locale]
        };
        if (lang.title === "" && lang.content === "") {
            return null;
        }
        return (
            <div className={'announcement'}>
                <CookieConsent
                    location="top"
                    buttonText={language.announcement.btnText[locale]}
                    cookieName="announcement_accepted"
                    style={{'backgroundColor': '#aecc53', 'zIndex': 99999}}
                >
                    {lang.title && (<h3>{lang.title}</h3>)}
                    {lang.content && (<p dangerouslySetInnerHTML={{__html: lang.content.replace("\r\n\r\n", "\r\n").replace("\r\n","</p><p>")}}/>)}
                </CookieConsent>
            </div>
        );
    }
}

Announcement.contextType = LocaleContext;

export default () => (
    <StaticQuery
        query={graphql` query {
          allWordpressAcfOptions {
            nodes {
              options {
                announcement_show
                announcement_title_en
                announcement_title_cy
                announcement_content_en
                announcement_content_cy
              }
            }
          }
        }
        `}
        render={(data) => (
            <Announcement announcement={data.allWordpressAcfOptions.nodes[0].options}/>
        )}
    />
)
